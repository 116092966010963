/* import __COLOCATED_TEMPLATE__ from './workflow-header.hbs'; */
/* RESPONSIBLE TEAM: team-workflows */
import Component from '@glimmer/component';
import { action } from '@ember/object';
import { inject as service } from '@ember/service';
import { restartableTask } from 'ember-concurrency-decorators';
import { OPERATOR_CUSTOM_BOT_OVERVIEW_ROUTE } from 'embercom/lib/operator/custom-bots/constants';
import {
  customBotObjectTypes,
  objectTypes,
} from 'embercom/models/data/matching-system/matching-constants';
import {
  OUTBOUND_EDITOR_ROUTES,
  OUTBOUND_INDEX_ROUTES,
  FIN_TASKS_ROUTES,
} from 'embercom/models/outbound/content-wrapper';
import PredicateGroup from 'predicates/models/predicate-group';
import { tracked } from '@glimmer/tracking';
import { isBlank } from '@ember/utils';

export default class WorkflowHeader extends Component {
  @service contentEditorService;
  @service store;
  @service router;
  @service intl;
  @service intercomConfirmService;
  @service appService;
  @service guideLibraryService;
  @service finStandaloneService;
  @service workflowLocalizationService;

  @tracked hasSaved = false;
  @tracked hasDismissedOptInCopy = false;

  maxTitleLength = 255;
  helpCollectionLink =
    'https://www.intercom.com/help/en/collections/2094823-installing-the-intercom-messenger';

  constructor() {
    super(...arguments);
    if (this.triggerPredicates && this.customBot && !this.customBot.triggerPredicateGroup) {
      this.customBot.triggerPredicateGroup = this.store.createFragment(
        'predicates/predicate-group',
        {
          predicates: PredicateGroup.convertRawPredicates(this.store, [
            {
              type: this.triggerPredicates.type,
              predicates: [this.triggerPredicates.options.firstObject.predicate],
            },
          ]),
        },
      );
    }

    //initialize the editor configs for all rulesets
    this.contentEditorService.ruleset.rulesetLinks.forEach((rulesetLink) => {
      if (customBotObjectTypes.includes(rulesetLink.objectType)) {
        rulesetLink.object.visualBuilderObject.editorConfig =
          rulesetLink.object.config.editorConfig;
      }
    });
  }

  get isInStandaloneSection() {
    return this.router.currentRouteName.includes('apps.app.standalone');
  }

  get triggerPredicates() {
    if (!this.customBot?.config) {
      return;
    }

    return this.customBot.config.triggerPredicates;
  }

  get customBot() {
    return this.contentEditorService.activeObject;
  }

  get isMessengerInstalled() {
    return this.guideLibraryService.isMessengerInstalled;
  }

  get showMessengerBanner() {
    return (
      this.contentEditorService?.activeRulesetLink?.objectType === objectTypes.inboundCustomBot
    );
  }

  get targetChannelIsOnlyEmail() {
    if (this.customBot.targetChannels?.every((item) => item === 'email')) {
      return true;
    }
  }

  get isBannerRequired() {
    if (this.appService.app?.canUseStandalone) {
      return false;
    }
    if (this.targetChannelIsOnlyEmail) {
      return false;
    }
    if (!this.isMessengerInstalled) {
      return true;
    }
  }

  get workflow() {
    let { editorConfig } = this.customBot.config;
    let workflow = this.customBot.visualBuilderObject;
    workflow.editorConfig = editorConfig;

    return workflow;
  }

  get editorConfiguration() {
    let { editorConfig } = this.customBot.config;
    if (!editorConfig) {
      throw new Error(
        `Cannot resolve path configuration class for custom bot type=${this.customBot.type}`,
      );
    }
    return editorConfig;
  }

  get isBackgroundWorkflow() {
    return this.contentEditorService.activeObject.visualBuilderObject?.isCustomerFacing === false;
  }

  get shouldShowOptInCopy() {
    return (
      !this.hasDismissedOptInCopy &&
      (this.customBot.shouldShowOptInCopyForAiAnswers ||
        this.customBot.shouldShowOptInCopyForAiCategorization ||
        this.customBot.shouldShowOptInCopyForAiAutoTranslation)
    );
  }

  get automaticOptInMessage() {
    if (this.customBot.shouldShowOptInCopyForAiCategorization) {
      return this.intl.t(
        'operator.workflows.visual-builder.classify-conversation-automatic-ai-opt-in',
        {
          htmlSafe: true,
        },
      );
    } else if (this.customBot.shouldShowOptInCopyForAiAutoTranslation) {
      return this.intl.t('operator.workflows.visual-builder.auto-translate-automatic-ai-opt-in', {
        htmlSafe: true,
      });
    }

    return this.intl.t('ai-agent.profiles.answers.ai.opt-in', {
      finPricingLearnMoreUrl: this.appService.app.finPricingLearnMoreUrl,
      htmlSafe: true,
    });
  }

  get validLocalVariableIdentifiersUsedInWorkflow() {
    return this.contentEditorService.activeObject.validLocalVariableIdentifiersUsedInWorkflow;
  }

  get setLiveErrors() {
    if (this.customBot.isAiAgentWorkflowTrigger) {
      return this._buildFinTaskValidationTooltipContent();
    }
  }

  @action
  async save() {
    if (
      this.args.config.preActivationCheck &&
      (this.contentEditorService.ruleset.isLive || this.contentEditorService.ruleset.isScheduled)
    ) {
      this.args.config.preActivationCheck(this.saveRuleset);
      return;
    }
    this.saveRuleset.perform();
    this.hasSaved = true;
  }

  @restartableTask
  *saveRuleset() {
    yield this.contentEditorService.saveRuleset.perform();
    if (this.args.config.postActivationCheck) {
      this.args.config.postActivationCheck();
    }
  }

  @action
  async close() {
    this.contentEditorService.uploadFileFinished();

    if (this.contentEditorService.ruleset.hasUnsavedChanges) {
      let confirmed = await this.confirmUnsavedChanges();
      if (!confirmed) {
        return;
      }

      this.contentEditorService.ruleset.rollbackAttributes();
    }

    if (this.contentEditorService.returnPath) {
      this.router.transitionTo(this.contentEditorService.returnPath);
    } else if (this.isInStandaloneSection) {
      this.router.transitionTo(this.finStandaloneService.workflowsReturnRoute);
    } else if (this.router.currentRouteName === FIN_TASKS_ROUTES.task) {
      this.router.transitionTo(FIN_TASKS_ROUTES.tasks);
    } else {
      this.contentEditorService.changeToViewMode();
      this.router.currentRouteName === OUTBOUND_EDITOR_ROUTES['custom_bot']
        ? this.router.transitionTo(OUTBOUND_INDEX_ROUTES['custom_bot'])
        : this.router.transitionTo(OPERATOR_CUSTOM_BOT_OVERVIEW_ROUTE);
    }
  }

  confirmUnsavedChanges() {
    return this.intercomConfirmService.confirm({
      title: this.intl.t('content-editor.headers.edit-mode.standard-header.unsaved-changes-title'),
      body: this.intl.t('content-editor.headers.edit-mode.standard-header.unsaved-changes-body'),
      confirmButtonText: this.intl.t(
        'content-editor.headers.edit-mode.standard-header.unsaved-changes-confirm',
      ),
      primaryButtonType: 'primary-destructive',
      cancelButtonText: this.intl.t(
        'content-editor.headers.edit-mode.standard-header.unsaved-changes-cancel',
      ),
    });
  }

  @action
  trimContentTitle() {
    this.contentEditorService.ruleset.clientData.title =
      this.contentEditorService.ruleset.clientData.title.trim();
  }

  _buildFinTaskValidationTooltipContent() {
    let missingFields = [];

    if (this.contentEditorService.ruleset.clientData.validations.attrs.title.isInvalid) {
      missingFields.push(
        this.contentEditorService.ruleset.clientData.validations.attrs.title.message,
      );
    }

    if (isBlank(this.customBot.description)) {
      missingFields.push(
        this.intl.t(
          'operator.workflows.visual-builder.workflow-trigger-node.fin-task.missing-description',
        ),
      );
    }

    let invalidSteps = this.customBot.visualBuilderObject.groups.toArray().flatMap((g) => {
      return g.steps.filter(
        (s) => s.type === 'operator/visual-builder/step/fin-custom' && s.validations.isInvalid,
      );
    });

    if (invalidSteps.toArray().some((step) => step.validations.attrs.instructions.isInvalid)) {
      missingFields.push(
        this.intl.t(
          'operator.workflows.visual-builder.workflow-trigger-node.fin-task.missing-instructions',
        ),
      );
    }

    if (invalidSteps.toArray().some((step) => step.validations.attrs.blocks.isInvalid)) {
      missingFields.push(
        this.intl.t(
          'operator.workflows.visual-builder.workflow-trigger-node.fin-task.missing-goal',
        ),
      );
    }

    if (missingFields.length === 0) {
      return null;
    }

    return this.intl
      .t(
        'operator.workflows.visual-builder.workflow-trigger-node.fin-task.set-live-missing-details-error',
      )
      .concat('<ul>')
      .concat(missingFields.map((field) => `<li>${field}</li>`).join(''))
      .concat('</ul>');
  }
}
