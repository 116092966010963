/* import __COLOCATED_TEMPLATE__ from './custom-bots-header.hbs'; */
/* RESPONSIBLE TEAM: team-workflows */
import Component from '@glimmer/component';
import { action } from '@ember/object';
import { tracked } from '@glimmer/tracking';
import { inject as service } from '@ember/service';
import type AiAgentSetupService from 'embercom/services/ai-agent-setup-service';

interface Args {
  launchModal: () => void;
  launchTriggerModal: () => void;
}

export default class CustomBotsHeader extends Component<Args> {
  @service declare aiAgentSetupService: AiAgentSetupService;

  @tracked video?: { id: string; titleKey: string };
  @tracked liveFinWorkflowsCount = 0;

  constructor(owner: unknown, args: Args) {
    super(owner, args);

    this._loadFinWorkflowsCount();
  }

  get learnList() {
    return [
      {
        items: [
          {
            component: 'custom-bots/learn-more-dropdown-item',
            componentShouldReplaceItem: true,
            componentAttrs: {
              openVideo: this.openVideoModal,
              closeVideo: this.closeVideoModal,
            },
          },
        ],
      },
    ];
  }

  async _loadFinWorkflowsCount() {
    await this.aiAgentSetupService.loadFinWorkflowsCount();

    this.liveFinWorkflowsCount =
      this.aiAgentSetupService.finStats.workflowsCount.liveFinWorkflowsCount;
  }

  @action
  openVideoModal(id: string, titleKey: string, event: Event) {
    event.preventDefault();
    event.stopPropagation();
    this.video = { id, titleKey };
  }

  @action
  closeVideoModal() {
    this.video = undefined;
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'CustomBots::CustomBotsHeader': typeof CustomBotsHeader;
  }
}
